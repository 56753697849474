import { escapeRegExp, throttle } from 'lodash-es';
import { createElement, defineModule } from '../../utils/helpers';
import { getSearchResults } from '../../api/search';

const getElements = () => ({
  searchFormElement: document.querySelector<HTMLFormElement>(
    '.search .search__form',
  ),
  searchInputElement: document.querySelector<HTMLInputElement>(
    '.search input[type="search"]',
  ),
  clearSearchElements: document.querySelectorAll<HTMLButtonElement>(
    '.search .search__clear',
  ),
  searchResultsElement: document.querySelector<HTMLButtonElement>(
    '.search .search__results',
  ),
});

const clearSearchInput = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLButtonElement)) return;

  const searchFormElement =
    e.currentTarget.closest<HTMLFormElement>('.search__form');
  if (!searchFormElement) return;

  const searchInputElement =
    searchFormElement.querySelector<HTMLInputElement>('.search__input');
  if (!searchInputElement) return;

  searchInputElement.value = '';
  searchInputElement.focus();
};

const throttledSearchRequest = throttle(async () => {
  const { searchInputElement, searchResultsElement } = getElements();
  if (!searchInputElement || !searchResultsElement) return;

  const query = searchInputElement.value;

  const searchResults = await getSearchResults(query, {
    perPage: 4,
  });

  const filteredData = searchResults?.data.filter((result) =>
    result.title.toLowerCase().includes(query.toLowerCase()),
  );
  if (!filteredData?.length) {
    searchResultsElement.classList.add('hidden');
    searchInputElement.setAttribute('aria-expanded', 'false');
    return;
  }

  searchResultsElement.replaceChildren(
    ...filteredData.map((searchResult) =>
      createElement('li', null, [
        createElement(
          'a',
          {
            className:
              'block px-3 py-2 text-p3 text-dark-gray hover:bg-light-gray',
            href: searchResult.url,
          },
          [
            createElement('p', { className: 'line-clamp-1' }, [
              searchResult.title.replace(
                new RegExp(escapeRegExp(query), 'gi'),
                (match) => `<span class="text-black">${match}</span>`,
              ),
            ]),
          ],
        ),
      ]),
    ),
  );

  searchResultsElement.classList.remove('hidden');
  searchInputElement.setAttribute('aria-expanded', 'true');
}, 300);

const onSearchInputTyping = async () => {
  const { searchInputElement, searchResultsElement } = getElements();
  if (!searchInputElement || !searchResultsElement) return;

  if (!searchInputElement.value.trim().length) {
    searchResultsElement.classList.add('hidden');
    searchInputElement.setAttribute('aria-expanded', 'false');
    return;
  }

  await throttledSearchRequest();
};

export default defineModule(
  async () => {
    const { searchInputElement, clearSearchElements } = getElements();
    if (searchInputElement?.value.trim().length) {
      await onSearchInputTyping();
    }

    searchInputElement?.addEventListener('input', onSearchInputTyping);

    clearSearchElements.forEach((clearSearchElement) =>
      clearSearchElement.addEventListener('click', clearSearchInput),
    );
  },
  () => {
    const { searchInputElement, clearSearchElements } = getElements();
    searchInputElement?.removeEventListener('input', onSearchInputTyping);
    clearSearchElements.forEach((clearSearchElement) =>
      clearSearchElement.removeEventListener('click', clearSearchInput),
    );
  },
);
