import type { WP_Post } from 'wp-types';
import { createElement, defineModule } from '../utils/helpers';
import { AJAX_API } from '../api/wordpress';
import type { Paginated } from '../types';

let page = 2;
let pages = 0;

const getElements = () => ({
  searchInputElement: document.querySelector<HTMLInputElement>(
    '.header__search input[type="search"]',
  ),
  searchResultsListElement: document.querySelector<HTMLUListElement>(
    '.search-results .search-results__list',
  ),
  searchResultsLoadMoreElement: document.querySelector<HTMLUListElement>(
    '.search-results .search-results__load-more',
  ),
});

const onLoadMoreClick = async () => {
  const {
    searchInputElement,
    searchResultsListElement,
    searchResultsLoadMoreElement,
  } = getElements();
  if (
    !searchInputElement ||
    !searchResultsListElement ||
    !searchResultsLoadMoreElement
  ) {
    return;
  }

  searchResultsLoadMoreElement.setAttribute('disabled', '');
  searchResultsLoadMoreElement.classList.add('btn--loading');

  try {
    const response = await AJAX_API.formData({
      action: 'custom_search',
      s: searchInputElement.value,
      page,
    })
      .post()
      .json<
        Paginated<
          (WP_Post & {
            post_type_label: string;
            post_thumbnail_url: string | null;
            post_permalink: string;
          })[]
        >
      >();

    page += 1;
    if (response.pages > pages) {
      pages = response.pages;
    }

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('page', `${page - 1}`);
    window.history.replaceState({}, document.title, currentUrl);

    if (page > pages) {
      searchResultsLoadMoreElement.remove();
    }

    searchResultsListElement.append(
      ...response.data.map((item) =>
        createElement('li', null, [
          createElement(
            'a',
            {
              href: item.post_permalink,
              className:
                'flex justify-between gap-x-gutter border-b border-black pb-6 lg:pb-4',
            },
            [
              createElement('div', null, [
                createElement('span', { className: 'tag' }, [
                  item.post_type_label,
                ]),
                createElement('h2', { className: 'text-p2 lg:text-p1 mt-4' }, [
                  item.post_title,
                ]),
              ]),
              item.post_thumbnail_url &&
                createElement('img', {
                  src: item.post_thumbnail_url,
                  className:
                    'shrink-0 object-cover rounded-lg bg-gray aspect-square w-20 lg:w-[12.125rem] h-full lg:aspect-[194/128]',
                }),
            ],
          ),
        ]),
      ),
    );
  } catch (error) {
    //
  } finally {
    searchResultsLoadMoreElement.removeAttribute('disabled');
    searchResultsLoadMoreElement.classList.remove('btn--loading');
  }
};

export default defineModule(
  () => {
    if (!document.body.classList.contains('search-results')) return;

    const { searchResultsLoadMoreElement } = getElements();

    searchResultsLoadMoreElement?.addEventListener('click', onLoadMoreClick);
  },
  () => {
    page = 2;
    pages = 0;

    if (!document.body.classList.contains('search-results')) return;

    const { searchResultsLoadMoreElement } = getElements();

    searchResultsLoadMoreElement?.removeEventListener('click', onLoadMoreClick);
  },
);
