import { defineModule } from '../utils/helpers';

const getElements = () => ({
  brighterMeterElements:
    document.querySelectorAll<HTMLElement>('.brighter-meter'),
});

const onBrighterMeterTogglerClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  // Prevent redirects on click in anchors
  e.stopImmediatePropagation();
  e.preventDefault();

  const brighterMeterElement =
    e.currentTarget.closest<HTMLElement>('.brighter-meter');
  if (!brighterMeterElement) return;

  const isOpen = brighterMeterElement.classList.toggle('brighter-meter--open');

  const brighterMeterTogglerElement =
    brighterMeterElement.querySelector<HTMLElement>('.brighter-meter__toggler');
  const brighterMeterContentElement =
    brighterMeterElement.querySelector<HTMLElement>('.brighter-meter__content');
  if (!brighterMeterTogglerElement || !brighterMeterContentElement) return;

  brighterMeterTogglerElement.setAttribute(
    'aria-expanded',
    (!isOpen).toString(),
  );
  brighterMeterContentElement.setAttribute('aria-hidden', isOpen.toString());
};

export default defineModule(
  () => {
    const { brighterMeterElements } = getElements();

    brighterMeterElements.forEach((brighterMeterElement) => {
      const brighterMeterTogglerElement =
        brighterMeterElement.querySelector<HTMLButtonElement>(
          '.brighter-meter__toggler',
        );
      if (!brighterMeterTogglerElement) return;

      brighterMeterTogglerElement.addEventListener(
        'click',
        onBrighterMeterTogglerClick,
      );
    });
  },
  () => {
    const { brighterMeterElements } = getElements();

    brighterMeterElements.forEach((brighterMeterElement) => {
      const brighterMeterTogglerElement =
        brighterMeterElement.querySelector<HTMLButtonElement>(
          '.brighter-meter__toggler',
        );
      if (!brighterMeterTogglerElement) return;

      brighterMeterTogglerElement.removeEventListener(
        'click',
        onBrighterMeterTogglerClick,
      );
    });
  },
);
