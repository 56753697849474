import {
  computePosition,
  shift,
  offset,
  autoUpdate,
  arrow,
} from '@floating-ui/dom';
import { createElement, defineModule } from '../utils/helpers';

let tooltipIndex = 0;
const TOOLTIP_SHOW_EVENTS = ['mouseenter', 'click', 'focus'];
const TOOLTIP_HIDE_EVENTS = ['mouseleave', 'blur'];

const autoUpdates: ReturnType<typeof autoUpdate>[] = [];

const getElements = () => ({
  pageWrapperElement: document.querySelector<HTMLElement>('.page-wrapper'),
  tooltipTriggerElements:
    document.querySelectorAll<HTMLElement>('[data-tooltip]'),
  tooltipElements: document.querySelectorAll<HTMLElement>('.tooltip'),
});

const updateTooltip = async ({
  tooltipElement,
  tooltipTriggerElement,
}: {
  tooltipElement: HTMLElement;
  tooltipTriggerElement: HTMLElement;
}) => {
  const arrowElement =
    tooltipElement.querySelector<HTMLElement>('.tooltip__arrow');

  const { middlewareData, placement, x, y } = await computePosition(
    tooltipTriggerElement,
    tooltipElement,
    {
      placement: 'top',
      middleware: [
        offset(16),
        shift(),
        arrowElement && arrow({ element: arrowElement }),
      ],
    },
  );

  Object.assign(tooltipElement.style, {
    left: `${x}px`,
    top: `${y}px`,
  });

  if (middlewareData.arrow && arrowElement) {
    const { x: arrowX, y: arrowY } = middlewareData.arrow;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    Object.assign(arrowElement.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      ...(staticSide && { [staticSide]: `${-arrowElement.offsetWidth / 2}px` }),
    });
  }
};

const showTooltip = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  // Prevent redirects on click in anchors
  e.stopImmediatePropagation();
  e.preventDefault();

  const tooltipId = e.currentTarget.getAttribute('aria-controls');
  if (!tooltipId) return;

  const tooltipElement = document.getElementById(tooltipId);
  if (!tooltipElement) return;

  tooltipElement.classList.remove('invisible', 'opacity-0');
  tooltipElement.setAttribute('aria-hidden', 'false');

  await updateTooltip({
    tooltipElement,
    tooltipTriggerElement: e.currentTarget,
  });
};

const hideTooltip = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  // Prevent redirects on click in anchors
  e.stopImmediatePropagation();
  e.preventDefault();

  const tooltipId = e.currentTarget.getAttribute('aria-controls');
  if (!tooltipId) return;

  const tooltipElement = document.getElementById(tooltipId);
  if (!tooltipElement) return;

  tooltipElement.classList.add('invisible', 'opacity-0');
  tooltipElement.setAttribute('aria-hidden', 'true');
};

export default defineModule(
  async () => {
    const { tooltipTriggerElements, pageWrapperElement } = getElements();
    if (!tooltipTriggerElements.length) return;

    tooltipTriggerElements.forEach((tooltipTriggerElement) => {
      const tooltipContent = tooltipTriggerElement.getAttribute('data-tooltip');
      if (!tooltipContent) return;

      tooltipIndex += 1;

      const tooltipArrowElement = createElement('span', {
        className:
          'tooltip__arrow absolute -z-[1] aspect-square w-3 rotate-45 bg-inherit',
      });

      const tooltipElement = createElement(
        'div',
        {
          id: `tooltip-${tooltipIndex}`,
          ariaHidden: true,
          role: 'tooltip',
          className:
            'tooltip absolute invisible z-50 rounded-lg opacity-0 max-w-[15rem] p-4 text-p3 bg-white drop-shadow-lg transition-[visibility,opacity] duration-200',
        },
        [
          createElement(
            'div',
            {
              className: 'tooltip__content',
            },
            [tooltipContent],
          ),
          tooltipArrowElement,
        ],
      );

      tooltipTriggerElement.setAttribute(
        'aria-controls',
        `tooltip-${tooltipIndex}`,
      );

      pageWrapperElement?.appendChild(tooltipElement);

      autoUpdates.push(
        autoUpdate(tooltipTriggerElement, tooltipElement, () =>
          updateTooltip({ tooltipElement, tooltipTriggerElement }),
        ),
      );

      TOOLTIP_SHOW_EVENTS.forEach((eventName) => {
        tooltipTriggerElement.addEventListener(eventName, showTooltip);
      });
      TOOLTIP_HIDE_EVENTS.forEach((eventName) => {
        tooltipTriggerElement.addEventListener(eventName, hideTooltip);
      });
    });
  },
  () => {
    const { tooltipTriggerElements, tooltipElements } = getElements();

    tooltipTriggerElements.forEach((tooltipTriggerElement) => {
      TOOLTIP_SHOW_EVENTS.forEach((eventName) => {
        tooltipTriggerElement.removeEventListener(eventName, showTooltip);
      });
      TOOLTIP_HIDE_EVENTS.forEach((eventName) => {
        tooltipTriggerElement.removeEventListener(eventName, hideTooltip);
      });
    });

    tooltipElements.forEach((tooltipElement) => tooltipElement.remove());

    while (autoUpdates.length > 0) {
      autoUpdates.pop()?.();
    }
  },
);
