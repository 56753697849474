import type Splide from '@splidejs/splide';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  succesStoriesSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--success-stories .splide',
  ),
});

export default defineModule(
  async () => {
    const { succesStoriesSplideElements } = getElements();
    if (!succesStoriesSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    succesStoriesSplideElements.forEach((succesStoriesSplideElement) => {
      const splide = new Splide(succesStoriesSplideElement, {
        type: 'loop',
        drag: true,
        mediaQuery: 'min',
        cloneStatus: false,
        focus: 'center',
        arrows: true,
        pagination: false,
        gap: '0.5rem',
        updateOnMove: true,
        speed: 600,
        breakpoints: {
          [BREAKPOINTS.sm]: {
            gap: '1.5rem',
          },
        },
      }).mount();

      splides.push(splide);
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
