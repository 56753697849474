import type Splide from '@splidejs/splide';
import { defineModule } from '../utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  brighterMeterSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--brighter-meter .splide',
  ),
});

export default defineModule(
  async () => {
    const { brighterMeterSplideElements } = getElements();
    if (!brighterMeterSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    brighterMeterSplideElements.forEach((brighterMeterSplideElement) => {
      const splide = new Splide(brighterMeterSplideElement, {
        type: 'slide',
        drag: 'free',
        pagination: false,
        autoWidth: true,
        snap: true,
        gap: 0,
        arrows: false,
      }).mount();

      const setCursor = (isDragging: boolean) => {
        const { track } = splide.Components.Elements;

        if (isDragging) {
          document.body.classList.add('cursor-grabbing');
          track.classList.remove('cursor-grab');
        } else {
          document.body.classList.remove('cursor-grabbing');
          track.classList.add('cursor-grab');
        }
      };

      splide.on('drag', () => setCursor(true));
      splide.on('dragged', () => setCursor(false));

      splides.push(splide);
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
