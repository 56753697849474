import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { defineModule } from '../../utils/helpers';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../../utils/overlays';
import { toggleMenu } from './toggle';

let focusTrap: FocusTrap | null = null;

const getElements = () => ({
  menuElement: document.querySelector<HTMLElement>('.menu'),
  menuSearchElement: document.querySelector<HTMLElement>('.menu__search'),
  menuSearchTogglerElement: document.querySelector<HTMLElement>(
    '.menu__search-toggler',
  ),
  searchInputElement: document.querySelector<HTMLInputElement>(
    '.menu__search input[type="search"]',
  ),
});

const toggleSearchMenu = (force?: boolean) => {
  const {
    menuElement,
    searchInputElement,
    menuSearchElement,
    menuSearchTogglerElement,
  } = getElements();
  if (
    !menuElement ||
    !searchInputElement ||
    !menuSearchElement ||
    !menuSearchTogglerElement
  ) {
    return;
  }

  force = force ?? !hasOpenOverlay(Overlay.SEARCH_MENU);

  if (force && hasOpenOverlay(Overlay.MENU)) {
    toggleMenu(false);
  }

  toggleOverlay(
    Overlay.SEARCH_MENU,
    menuElement.classList.toggle('menu--search', force),
  );

  menuSearchTogglerElement.ariaExpanded = `${hasOpenOverlay(
    Overlay.SEARCH_MENU,
  )}`;

  if (force) {
    window.scrollTo({ top: 0, behavior: 'instant' });

    focusTrap = createFocusTrap(menuElement, {
      initialFocus: menuElement,
      allowOutsideClick: false,
      escapeDeactivates: false,
    }).activate();

    menuSearchElement.addEventListener(
      'transitionend',
      () => {
        searchInputElement.focus();
      },
      { once: true },
    );
  } else {
    focusTrap?.deactivate();
  }
};

const onMenuSearchTogglerClick = () => toggleSearchMenu();

const onEscape = (e: KeyboardEvent) => {
  if (
    !hasOpenOverlay(Overlay.SEARCH_MENU) ||
    e.key !== 'Escape' ||
    ['button', 'a'].includes(document.activeElement!.tagName.toLowerCase())
  ) {
    return;
  }

  toggleSearchMenu(false);
};

const handleOutsideSearchClick = (e: Event) => {
  if (!(e.target instanceof HTMLElement)) return;

  const { menuSearchElement } = getElements();

  const isOutsideClick = e.target === menuSearchElement;
  if (isOutsideClick) {
    toggleSearchMenu(false);
  }
};

export default defineModule(
  () => {
    const { menuSearchTogglerElement } = getElements();
    menuSearchTogglerElement?.addEventListener(
      'click',
      onMenuSearchTogglerClick,
    );
    document.addEventListener('keydown', onEscape);
    document.addEventListener('click', handleOutsideSearchClick);
  },
  () => {
    const { menuSearchTogglerElement } = getElements();
    menuSearchTogglerElement?.removeEventListener(
      'click',
      onMenuSearchTogglerClick,
    );
    document.removeEventListener('keydown', onEscape);
    document.removeEventListener('click', handleOutsideSearchClick);
    focusTrap?.deactivate();
  },
);
