import type Splide from '@splidejs/splide';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  stepsSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--steps .splide',
  ),
});

export default defineModule(
  async () => {
    const { stepsSplideElements } = getElements();
    console.log(stepsSplideElements);

    if (!stepsSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    stepsSplideElements.forEach((stepsSplideElement) => {
      const splide = new Splide(stepsSplideElement, {
        type: 'slide',
        drag: 'free',
        pagination: false,
        autoWidth: true,
        snap: true,
        gap: 0,
        mediaQuery: 'min',
        focus: 'center',
        arrows: false,
        breakpoints: {
          [BREAKPOINTS.md]: {
            destroy: true,
          },
        },
      }).mount();

      const setCursor = (isDragging: boolean) => {
        const { track } = splide.Components.Elements;

        if (isDragging) {
          document.body.classList.add('cursor-grabbing', 'md:cursor-auto');
          track.classList.remove('cursor-grab');
        } else {
          document.body.classList.remove('cursor-grabbing', 'md:cursor-auto');
          track.classList.add('cursor-grab');
        }
      };

      splide.on('drag', () => setCursor(true));
      splide.on('dragged', () => setCursor(false));

      splides.push(splide);
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
