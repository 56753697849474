import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { defineModule } from '../../utils/helpers';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../../utils/overlays';

let focusTrap: FocusTrap | null = null;

const getElements = () => ({
  menuElement: document.querySelector<HTMLElement>('.menu'),
  menuTogglerElement: document.querySelector<HTMLElement>('.menu__toggler'),
});

export const toggleMenu = (force?: boolean) => {
  const { menuElement, menuTogglerElement } = getElements();
  if (!menuElement || !menuTogglerElement) return;

  force = force ?? !hasOpenOverlay(Overlay.MENU);

  toggleOverlay(
    Overlay.MENU,
    menuElement.classList.toggle('menu--open', force),
  );

  menuTogglerElement.ariaExpanded = `${hasOpenOverlay(Overlay.MENU)}`;

  if (force) {
    window.scrollTo({ top: 0, behavior: 'instant' });

    focusTrap = createFocusTrap(menuElement, {
      initialFocus: menuElement,
      allowOutsideClick: false,
      escapeDeactivates: false,
    }).activate();
  } else {
    focusTrap?.deactivate();
  }
};

const onMenuTogglerClick = () => toggleMenu();

export default defineModule(
  () => {
    const { menuTogglerElement } = getElements();
    menuTogglerElement?.addEventListener('click', onMenuTogglerClick);
  },
  () => {
    const { menuTogglerElement } = getElements();
    menuTogglerElement?.removeEventListener('click', onMenuTogglerClick);

    focusTrap?.deactivate();
  },
);
