import type { Splide } from '@splidejs/splide';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  teamElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--team',
  ),
});

export default defineModule(
  async () => {
    const { teamElements } = getElements();

    if (!teamElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    teamElements.forEach((teamElement) =>
      splides.push(
        new Splide(teamElement, {
          speed: 500,
          perPage: 2,
          gap: '1.5rem',
          pagination: false,
          mediaQuery: 'min',
          breakpoints: {
            [BREAKPOINTS.md]: {
              perPage: 3,
              padding: {
                left: '0',
                right: '0',
              },
            },
            [BREAKPOINTS.lg]: {
              perPage: 4,
            },
          },
        }).mount(),
      ),
    );
  },
  () => {
    while (splides.length > 0) splides.pop()!.destroy(true);
  },
);
