import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';
import FormDataAddon from 'wretch/addons/formData';

export const API = wretch(`${window.location.origin}/wp-json/wp/v2`).addon(
  QueryStringAddon,
);

export const AJAX_API = wretch(
  `${window.location.origin}/wp/wp-admin/admin-ajax.php`,
).addon(FormDataAddon);
