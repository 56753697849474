import { defineModule } from '../utils/helpers';

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--faq .accordion',
  ),
});

const onAccordionButtonClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const faqContentBlockElement = e.currentTarget.closest<HTMLElement>(
    '.content-block--faq',
  );
  const accordionElement = e.currentTarget.closest<HTMLElement>('.accordion');
  if (!faqContentBlockElement || !accordionElement) return;

  const isOpen = accordionElement.classList.contains('accordion--open');

  const accordionElements =
    faqContentBlockElement.querySelectorAll<HTMLElement>('.accordion');
  accordionElements.forEach((_accordionElement) => {
    const isCurrentElement = _accordionElement === accordionElement;
    const shouldOpen = isCurrentElement && !isOpen;

    _accordionElement.classList.toggle('accordion--open', shouldOpen);

    const accordionTriggerElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__trigger');
    const accordionContentElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__content');
    if (!accordionTriggerElement || !accordionContentElement) return;

    accordionTriggerElement.setAttribute(
      'aria-expanded',
      shouldOpen.toString(),
    );
    accordionContentElement.setAttribute(
      'aria-hidden',
      (!shouldOpen).toString(),
    );
  });
};

export default defineModule(
  () => {
    const { accordionElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordionTriggerElement =
        accordionElement.querySelector<HTMLButtonElement>(
          '.accordion__trigger',
        );
      if (!accordionTriggerElement) return;

      accordionTriggerElement.addEventListener('click', onAccordionButtonClick);
    });
  },
  () => {
    const { accordionElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordionTriggerElement =
        accordionElement.querySelector<HTMLButtonElement>(
          '.accordion__trigger',
        );
      if (!accordionTriggerElement) return;

      accordionTriggerElement.removeEventListener(
        'click',
        onAccordionButtonClick,
      );
    });
  },
);
