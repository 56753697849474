import type { WP_REST_API_Search_Result } from 'wp-types';
import type { Paginated } from '../types';
import { API } from './wordpress';

interface SearchOptions {
  page?: number;
  perPage?: number;
}

const DEFAULT_OPTIONS: Partial<SearchOptions> = {
  page: 1,
  perPage: 10,
};

export const getSearchResults = async (
  query: string,
  options: SearchOptions = {},
) => {
  if (!query.trim().length) return;

  options = { ...DEFAULT_OPTIONS, ...options };

  const request = API.query({
    search: query,
    type: 'post',
    per_page: options.perPage,
    page: options.page,
    subtype: ['page', 'post', 'vacancies'].join(','),
  }).get('/search');

  const [response, data] = await Promise.all([
    request.res(),
    request.json<WP_REST_API_Search_Result[]>(),
  ]);

  const pages = parseInt(response.headers.get('X-WP-TotalPages') ?? '1');
  const total = parseInt(response.headers.get('X-Wp-Total') ?? '1');

  return {
    data,
    pages,
    page: options.page!,
    total,
  } satisfies Paginated<typeof data>;
};
