import { defineModule } from '../utils/helpers';

const getElements = () => ({
  listElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--offering-and-expectations .offering-and-expectations__list',
  ),
});

const onTriggerButtonClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const listElement = e.currentTarget.closest<HTMLElement>(
    '.offering-and-expectations__list',
  );
  if (!listElement) return;

  const isOpen = listElement.classList.toggle('list--open');

  const listTriggerElement =
    listElement.querySelector<HTMLElement>('.list__trigger');
  const listContentElement =
    listElement.querySelector<HTMLElement>('.list__content');
  if (!listTriggerElement || !listContentElement) return;

  listTriggerElement.setAttribute('aria-expanded', (!isOpen).toString());
  listContentElement.setAttribute('aria-hidden', isOpen.toString());
};

export default defineModule(
  () => {
    const { listElements } = getElements();

    listElements.forEach((listElement) => {
      const listTriggerElement =
        listElement.querySelector<HTMLButtonElement>('.list__trigger');
      if (!listTriggerElement) return;

      listTriggerElement.addEventListener('click', onTriggerButtonClick);
    });
  },
  () => {
    const { listElements } = getElements();

    listElements.forEach((listElement) => {
      const listTriggerElement =
        listElement.querySelector<HTMLButtonElement>('.list__trigger');
      if (!listTriggerElement) return;

      listTriggerElement.removeEventListener('click', onTriggerButtonClick);
    });
  },
);
